<template>
  <CRow>
    <CCol cols="12" lg="12" md="12">
      <CCard class="mb-0">
        <CCardHeader>
          <CRow>
            <CCol cols="5" xl="5" class="pt-0 pb-0 text-left">
              {{ $t('todo.Todo_templates') }}
            </CCol>
            <CCol cols="7" xl="7" class="pt-0 pb-0 text-xl-right">
              <b-switch class="mb-0 mt-1 mt-xl-0" v-model="showInactive" size="is-small" @input="getTemplateData()">{{ $t('common.show_inactive') }}</b-switch>
              <b-switch class="mb-0 mt-1 mt-xl-0 enable_search" v-model="searchEnabled" size="is-small">{{ $t('common.activate_search') }}</b-switch>
              <div class="mt-1 mt-xl-0 d-inline-block align-top">
                <CButton class="ml-2 link" color="primary" @click="templateModal = true; resetTemplateData();">
                  <i class="fas fa-plus mr-1"/>
                  <span>{{ $t('todo.Add_template') }}</span>
                </CButton>
              </div> 
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <div class="multiselect_container">
                <label>{{ $t('common.filter_by_category') }}</label>
                <multiselect
                  class="data_table"
                  v-model="filter.categories"
                  :options="dropdownTodoListCategories" 
                  :multiple="true"
                  :group-select="false"
                  :placeholder="$t('common.filter_by_category')"
                  :hideSelected="true"
                  :closeOnSelect="false"
                  :selectLabel="$t('common.Add_category')" 
                  :selectedLabel="$t('Added')"
                  :deselectLabel="$t('common.Remove_category')"
                  track-by="todo_list_category_name" 
                  label="todo_list_category_name"
                  @input="updateTemplateFilter();">
                  <span slot="noResult">{{ $t('Nothing_found') }}</span>
                </multiselect>
              </div>
              <b-table ref="templateTable" class="data_table" :data="templates" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition" detailed detail-key="todo_list_template_id_external" :show-detail-icon="true">
                <template slot-scope="template">
                  <b-table-column field="template_name" :label="$t('todo.Template')" :searchable="searchEnabled" width="20%">
                    <quick-edit type="text" mode="ignore" :buttonOkText="$t('ok')" :buttonCancelText="$t('cancel')" v-model="template.row.template_name" v-on:input="updateTemplate(template.row.todo_list_template_id_external, template.row)">
                      <span>{{template.row.template_name}}<i class="icon edit fas fa-pen ml-1"/></span>
                    </quick-edit>
                  </b-table-column>
                  <b-table-column field="todo_list_category_name" :label="$t('Category')" :searchable="searchEnabled" width="15%">
                    <quick-edit type="select" :options="dropdownTodoListCategories" mode="ignore" :buttonOkText="$t('ok')" :buttonCancelText="$t('cancel')" v-model="template.row.todo_list_category_id" v-on:input="updateTemplate(template.row.todo_list_template_id_external, template.row)">
                      <span>{{template.row.todo_list_category_name}}<i class="icon edit fas fa-pen ml-1"/></span>
                    </quick-edit>
                  </b-table-column>
                  <b-table-column field="groups" :label="$t('common.Specified_for_groups')">
                    <multiselect
                      class="data_table"
                      v-model="template.row.groups" 
                      :options="groups" 
                      :multiple="true"
                      :placeholder="$t('common.Select_group')" 
                      :selectLabel="$t('common.Add_group')" 
                      :selectedLabel="$t('Added')"
                      :deselectLabel="$t('common.Remove_group')"
                      track-by="group_id" 
                      label="title"
                      @input="updateTemplate(template.row.todo_list_template_id_external, template.row)">
                      <span slot="noResult">{{ $t('common.no_groups_found') }}</span>
                    </multiselect>  
                  </b-table-column>
                  <b-table-column field="nr_of_items" :label="$t('todo.No_of_items')" width="10%">
                    <span>{{template.row.nr_of_items > 0 ? template.row.nr_of_items + ' ' + $t('todo.items') : '-'}}</span>
                  </b-table-column>
                  <b-table-column field="duration" :label="$t('todo.Est_duration')" width="10%">
                    <span>{{template.row.estimated_duration > 0 ? template.row.estimated_duration + ' ' + $t('common.minutes') : '-'}}</span>
                  </b-table-column>
                  <b-table-column field="actions" width="15%">
                    <CButton class="mr-2 d-inline-block" color="primary" @click="templateModal = true; templateModalData = template.row; templateModalData.image_url = null;">
                      <i class="fas fa-pen"/>
                    </CButton>
                    <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="template.row.is_active" size="is-small" @input="updateTemplate(template.row.todo_list_template_id_external, template.row);">{{ $t('is_active') }}</b-switch>
                  </b-table-column>
                </template>
                <template slot="empty">
                  <div class="p-2 text-center">
                    <loadingSpinner mode="auto" v-if="!overviewDataLoaded" :content="$t('todo.Loading_templates')"/>
                    <span v-else>{{ $t('todo.No_templates_found') }}</span>
                  </div>                
                </template>
                <template slot="detail" slot-scope="template">
                  <b-table ref="itemTable" class="data_table" :data="template.row.items" :striped="true" :hoverable="true" :mobile-cards="true" draggable @dragstart="dragStepstart" @drop="dropStep" @dragover="dragStepover" @dragleave="dragStepleave">
                    <template slot-scope="item">
                      <b-table-column field="item_order" :label="$t('Order')" width="5%">
                        <span>{{item.row.item_order + 1}}</span>
                      </b-table-column>                                     
                      <b-table-column field="title" :label="$t('todo.Item')" width="20%">
                        <quick-edit type="text" mode="ignore" :buttonOkText="$t('ok')" :butonCancelText="$t('cancel')" v-model="item.row.title" v-on:input="updateItem(item.row.todo_list_template_item_id, item.row, template.row.todo_list_template_id_external)">{{item.row.title}}<i class="icon edit fas fa-pen ml-1"/></quick-edit>
                      </b-table-column>
                      <b-table-column field="title" :label="$t('common.Description')">                      
                        <quick-edit type="textarea" rows="3" mode="ignore" :buttonOkText="$t('ok')" :butonCancelText="$t('cancel')" v-model="item.row.description" v-on:input="updateItem(item.row.todo_list_template_item_id, item.row, template.row.todo_list_template_id_external)">
                          <span v-html="item.row.description !== null ? item.row.description : $t('common.Add_content')"></span>
                          <i class="icon edit fas fa-pen ml-1"/>
                        </quick-edit>
                      </b-table-column>                      
                      <b-table-column field="duration" :label="$t('todo.Est_duration')" width="10%">
                        <span>{{item.row.estimated_duration > 0 ? item.row.estimated_duration + ' ' + $t('common.minutes') : '-'}}</span>
                      </b-table-column>
                      <b-table-column field="actions" label="" width="15%">
                        <CButton class="mr-2 d-inline-block" color="primary" @click="itemModal = true; itemModalData = item.row; itemModalData.todo_list_template_id_external = template.row.todo_list_template_id_external;">
                          <i class="fas fa-pen"/>
                        </CButton>
                        <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="item.row.is_active" size="is-small" @input="updateItem(item.row.todo_list_template_item_id, item.row, template.row.todo_list_template_id_external);">{{ $t('is_active') }}</b-switch>
                      </b-table-column>
                    </template>
                    <template slot="empty">
                      <div class="p-2 text-center">
                        <span>{{ $t('todo.No_items_found') }}</span>
                      </div>                       
                    </template>                  
                  </b-table>

                  <CButton class="mt-2 link" color="primary" @click="itemModal = true; resetItemData(); itemModalData.todo_list_template_id_external = template.row.todo_list_template_id_external;">
                    <i class="fas fa-plus mr-1"/>
                    <span>{{ $t('todo.Add_item') }}</span>
                  </CButton>

                </template>                
              </b-table>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  
    <b-modal class="todo" :can-cancel="[]" :active.sync="templateModal" :width="960" scroll="keep">
      <CCard class="mb-0 d-block">
        <CCardHeader class="pb-0">
          {{templateModalData.todo_list_template_id === null || templateModalData.todo_list_template_id === undefined ? $t('todo.New_template') : $t('todo.Edit_template')}}
        </CCardHeader>
        <CCardBody class="w-100">
          <CRow>
            <CCol cols="6" lg="6" class="pt-0 pb-0">
              <CInput type="text" :label="$t('todo.Template_name')" v-model="templateModalData.template_name" required was-validated/>
            </CCol>
            <CCol cols="6" lg="6" class="pt-0 pb-0">
              <CSelect :label="$t('Category')" :value.sync="templateModalData.todo_list_category_id" :options="dropdownTodoListCategories" :placeholder="$t('select_a') + $t('category')" required was-validated/>
            </CCol>
            <CCol cols="12" lg="12" class="pt-0 pb-0">              
              <label>{{ $t('todo.Template_description') }}</label>
              <div class="wysiwyg mb-2">
                <ckeditor :editor="editor" v-model="templateModalData.description" :config="editorConfig" :placeholder="$t('todo.Template_description')"></ckeditor>
              </div>
            </CCol>
            <CCol cols="6" lg="6" class="pt-0 pb-0">
              <label>{{$t('todo.upload_template_picture')}}</label>
              <div v-if="templateModalData.image_url !== null" class="image_preview mb-3">
                <img :src="templateModalData.image_url">
              </div>
              <div>
                <input id="imageUpload" multiple type="file" accept="image/*" :value="templateModalData.uploaded_post_images" @change="onImageChange" hidden>
                <CButton color="primary" @click="pickImageToUpload();"><i class="fas fa-image mr-1"/>{{$t('todo.upload_picture')}}</CButton>
              </div>
            </CCol>
            <CCol v-if="(templateModalData.todo_list_template_id !== null && templateModalData.todo_list_template_id !== undefined) && templateModalData.image !== null" cols="6" lg="6" class="pt-0 pb-0">
              <label>{{ $t('todo.current_picture') }}</label>
              <div class="current_images" v-bind:class="{ 'mb-3' : templateModalData.image !== null }">
                <img :src="apiBaseUrl + '/v1/common/cdn/file/image/hub-todo/' + templateModalData.todo_list_template_id_external + '/' + templateModalData.image + '/' + clientToken"/>              
              </div>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <div v-if="templateModalData.todo_list_template_id === null || templateModalData.todo_list_template_id === undefined">
            <CButton color="primary" @click="insertTemplate();"><i class="fas fa-save mr-1"/>{{ $t('Save') }}</CButton>
            <CButton color="secondary" @click="templateModal = false; resetTemplateData(); newTemplateUUID();"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
          </div>
          <div v-else>
            <CButton color="primary" @click="updateTemplate(templateModalData.todo_list_template_id_external, templateModalData);"><i class="fas fa-save mr-1"/>{{ $t('Update') }}</CButton>
            <CButton color="secondary" @click="templateModal = false;"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
          </div>
        </CCardFooter>          
      </CCard>
    </b-modal>

    <b-modal class="todo" :can-cancel="[]" :active.sync="itemModal" :width="960" scroll="keep">
      <CCard class="mb-0 d-block">
        <CCardHeader class="pb-0">
          {{itemModalData.todo_list_template_item_id === null || itemModalData.todo_list_template_item_id === undefined ? $t('todo.Add_item') : $t('todo.Edit_item')}}
        </CCardHeader>
        <CCardBody class="w-100">
          <CRow>
            <CCol cols="6" lg="6" class="pt-0 pb-0">
              <CSelect :label="$t('todo.Template')" :value.sync="itemModalData.todo_list_template_id_external" :options="dropdownTemplates" :placeholder="$t('select_a') + $t('todo.template')" required was-validated disabled/>
            </CCol>                     
            <CCol cols="6" lg="6" class="pt-0 pb-0">
              <CInput type="text" :label="$t('common.Title')" class="mb-0" v-model="itemModalData.title" required was-validated/>
            </CCol>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <label>{{ $t('todo.item_description') }}</label>
              <div class="wysiwyg mb-2">
                <ckeditor :editor="editor" v-model="itemModalData.description" :config="editorConfig" :placeholder="$t('todo.item_description')"></ckeditor>
              </div>
            </CCol>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <CInput type="number" step="1" min="0" max="120" :label="$t('todo.Estimated_duration')" class="mb-0" v-model="itemModalData.estimated_duration" required was-validated/>
            </CCol>                      
          </CRow>            
        </CCardBody>
        <CCardFooter>          
          <div v-if="itemModalData.todo_list_template_item_id === null || itemModalData.todo_list_template_item_id === undefined">
            <CButton color="primary" @click="insertItem();"><i class="fas fa-save mr-1"/>{{ $t('Save') }}</CButton>
            <CButton color="secondary" @click="itemModal = false; resetItemData();"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
          </div>
          <div v-else>
            <CButton color="primary" @click="updateItem(itemModalData.todo_list_template_item_id, itemModalData, itemModalData.todo_list_template_id_external);"><i class="fas fa-save mr-1"/>{{ $t('Update') }}</CButton>
            <CButton color="secondary" @click="itemModal = false;"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
          </div>
        </CCardFooter>          
      </CCard>
    </b-modal>

  </CRow>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import Multiselect from 'vue-multiselect';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import loadingSpinner from '@/components/common/loadingSpinner.vue'
import { v4 as uuidv4 } from 'uuid';
import { validate as uuidValidate } from 'uuid';

export default {
  name: 'Templates',
  components: {
    QuickEdit,
    Multiselect,
    loadingSpinner
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      groups: [],
      templates: [],
      overviewDataLoaded: false,
      dropdownTemplates: [],
      dropdownTodoListCategories: [],
      filter: { categories: [] },
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 15,
      searchEnabled: false,
      showInactive: false,
      templateModal: false,
      templateModalData: {},
      templateImageUploaded: false,
      itemModal: false,
      itemModalData: {},
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ]
      },
    }
  },
  methods: {  
    getTemplateData () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/hub/todo/templates/overview')
      .then(res => {        
        this.templates = res.data.data;
        this.overviewDataLoaded = true;

        if(!this.showInactive) {
          this.templates = this.templates.filter( i => ['Y'].includes( i.active ) );
        }

        // Set the estimated duration for the templates
        for(var t = 0; t < this.templates.length; t++) {
          // Set default estimated duration for template
          this.templates[t].estimated_duration = 0;
          // Loop through items in template
          for(var i = 0; i < this.templates[t].items.length; i++) {
            if(this.templates[t].items[i].is_active === true) {
              this.templates[t].estimated_duration += this.templates[t].items[i].estimated_duration;
            }
          }
        }

        if(this.filter.categories.length > 0) {
          let selectedCategories = [];
          // Gather ID's of selected categories
          for(var c = 0; c < this.filter.categories.length; c++) {
            selectedCategories.push(this.filter.categories[c].todo_list_category_id);
          }
          // Return intents from selected categories
          this.templates = this.templates.filter(function(item) {
            return selectedCategories.includes(item.todo_list_category_id); 
          })
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTemplateDetails (id) {
      let templateIdExternal = id;

      axios.get(process.env.VUE_APP_API_URL + '/v1/hub/todo/template/' + templateIdExternal)
      .then(res => {
        let updatedTemplate = res.data.data;

        // Check if table refs are available
        if(this.$refs !== undefined && this.$refs.templateTable !== undefined ) {

          let templateIndex = this.$refs.templateTable.data.findIndex(x => x.todo_list_template_id_external == updatedTemplate.todo_list_template_id_external);

          this.$refs.templateTable.data[templateIndex].todo_list_category_id = updatedTemplate.todo_list_category_id;
          this.$refs.templateTable.data[templateIndex].name = updatedTemplate.name;
          this.$refs.templateTable.data[templateIndex].description = updatedTemplate.description;
          this.$refs.templateTable.data[templateIndex].nr_of_items = updatedTemplate.nr_of_items;
          this.$refs.templateTable.data[templateIndex].items = updatedTemplate.items;
          this.$refs.templateTable.data[templateIndex].image = updatedTemplate.image;

          // Set the estimated duration for the templates
          for(var t = 0; t < this.templates.length; t++) {
            // Set default estimated duration for template
            this.templates[t].estimated_duration = 0;
            // Loop through items in template
            for(var i = 0; i < this.templates[t].items.length; i++) {
              if(this.templates[t].items[i].is_active === true) {
                this.templates[t].estimated_duration += this.templates[t].items[i].estimated_duration;
              }
            }
          }
        }        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTemplates () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/hub/todo/templates/select')
      .then(res => {
        this.dropdownTemplates = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTemplateCategories () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/hub/todo/categories')
      .then(res => {
        this.dropdownTodoListCategories = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },         
    insertTemplate () {
      let params = {};
      params = this.templateModalData;
      
      let template_name = params.template_name;
      let todo_list_category_id = params.todo_list_category_id;

      if(template_name && todo_list_category_id) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/hub/todo/template', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('todo.Template_added'), type: 'is-success', duration: 3000 });
          // Update the courses dropdown
          this.getTemplates();
          // Update the course data
          this.getTemplateData();
          // Reset the course modal
          this.resetTemplateData();
          // Get new UUID for next new course
          this.newTemplateUUID();
          // Hide the modal
          this.templateModal = false;
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 3000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateTemplate (id, data) {
      let templateIdExternal = id;
      let params = {}
      params = data;
      (params.is_active == true) ? params.active = 'Y' : params.active = 'N';
              
      axios.put(process.env.VUE_APP_API_URL + '/v1/hub/todo/template/' + templateIdExternal, params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('todo.Template_updated'), type: 'is-success', duration: 3000 });
        // Update the template details
        this.getTemplateDetails(templateIdExternal);
        // Close the template modal
        this.templateModal = false;
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    insertItem () {
      let params = {};
      params = this.itemModalData;
      
      let todo_list_template_id_external = params.todo_list_template_id_external;
      let title = params.title;

      if(todo_list_template_id_external && title) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/hub/todo/template/item', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('todo.Item_added'), type: 'is-success', duration: 3000 });
          // Update the course data
          this.getTemplateDetails(this.itemModalData.todo_list_template_id_external);
          // Reset the chapter model
          this.resetItemData();
          this.itemModal = false;
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 3000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 3000 });
      }
    },
    updateItem (itemId, data, templateIdExternal) {
      let params = {}
      params = data;
      (params.is_active == true) ? params.active = 'Y' : params.active = 'N';
              
      axios.put(process.env.VUE_APP_API_URL + '/v1/hub/todo/template/' + itemId + '/items', params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('todo.Item_updated'), type: 'is-success', duration: 2000 });
        // Update the template details
        this.getTemplateDetails(templateIdExternal);
        // Close the item modal
        this.itemModal = false;
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },    
    updateItemOrder (data) {
      let params = {}
      params = data;
                
      axios.put(process.env.VUE_APP_API_URL + '/v1/hub/todo/template/item/order', params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('todo.Item_order_updated'), type: 'is-success', duration: 2000 });
        // Update the course details
        this.getTemplateDetails(data.todo_list_template_id_external);
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },       
    resetTemplateData () {
      this.templateModalData = {
        todo_list_template_id_external: null,
        todo_list_category_id: null,
        template_name: '',
        description: '',
        image: null,
        image_url: null
      };

      this.templateModalData.todo_list_template_id_external = this.newTemplateExternalId;
    },
    resetItemData () {
      this.itemModalData = {
        todo_list_template_id_external: null,
        title: '',
        description: '',
        estimated_duration: 0
      }
    },      
    onImageChange(e) {
      const file = e.target.files[0];
      this.templateModalData.image_url = URL.createObjectURL(file);
      
      const templateIdExternal = this.templateModalData.todo_list_template_id_external;

      const formData = new FormData();
      const imagefile = document.querySelector('#imageUpload');
      formData.append("image", imagefile.files[0]);

      axios.post('v1/common/upload/image/todo/' + templateIdExternal, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(res => {
        this.templateModalData.image = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });      
    },    
    pickImageToUpload() {
      document.getElementById("imageUpload").click()
    },
    updateTemplateFilter() {
      // Save multiselect categories to localStorage
      localStorage.setItem('templateFilter', JSON.stringify(this.filter.categories));
      // Gather the intents
      this.getTemplateData();
    },           
    dragStepstart (payload) {
      this.draggingRow = payload.row
      this.draggingRowIndex = payload.index
      // payload.event.dataTransfer.effectAllowed = 'copy'
    },
    dragStepover (payload) {
      // payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragStepleave (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    dropStep (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      const droppedOnRowIndex = payload.index    
      this.draggingRow.new_item_order = droppedOnRowIndex;
      this.draggingRow.old_item_order = this.draggingRowIndex;      
      this.updateItemOrder(this.draggingRow);
    },
    newTemplateUUID () {
      let params = {};
      params.uuid = uuidv4();

      axios.post(process.env.VUE_APP_API_URL + '/v1/hub/todo/template/checkUUID', params)
      .then(res => {
        this.newTemplateExternalId = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      })  
    },  
  },
  mounted: function(){
    // Get new UUID for next new template
    this.newTemplateUUID();

    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    
    // If available, set the filter according to localStorage data
    if(localStorage.getItem('templateFilter') !== null && localStorage.getItem('templateFilter') !== undefined) {
      this.filter.categories = JSON.parse(localStorage.getItem('templateFilter')); 
    }    
    // Get templates
    this.getTemplateData();
    // Get data for dropdowns
    this.getTemplates();
    this.getTemplateCategories();
    // Reset data
    this.resetTemplateData();
    this.resetItemData();  

    axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/multiselect')
    .then(res => {
      this.groups = res.data.data;
    })
    .catch(err => {
      console.error(err); 
    });
  }
}
</script>